import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { SharedModule } from '../shared.module';
import { FormControl, Validators } from '@angular/forms';
import { VERSION_PATTERN } from '../../core/config/app.constants';
import { ValidationsService } from '../../core/helpers/validations.service';

@Component({
  templateUrl: './version-input-dialog.component.html',
  styleUrls: ['./version-input-dialog.component.scss'],
  standalone: true,
  imports: [MatButton, MatDialogActions, MatDialogContent, MatDialogTitle, MatIcon, MatIconButton, NgIf, SharedModule],
})
export class VersionInputDialogComponent implements OnInit {
  versionInputControl: FormControl<string>;

  constructor(
    public dialogRef: MatDialogRef<VersionInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      defaultVersion: string;
    },
    private validationsService: ValidationsService,
  ) {}

  ngOnInit(): void {
    this.versionInputControl = new FormControl(this.data.defaultVersion, [
      Validators.required,
      Validators.pattern(VERSION_PATTERN),
      this.validationsService.minVersionValidator(this.data.defaultVersion),
    ]);
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  onConfirmClick(): void {
    if (this.versionInputControl.invalid) {
      this.versionInputControl.markAsTouched();
      return;
    }

    this.dialogRef.close(this.versionInputControl.value);
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }
}
