<button class="close-icon" mat-icon-button (click)="onCloseClick()">
  <mat-icon class="button-block_small-icon" svgIcon="close" />
</button>

<h1 class="text-center" mat-dialog-title>{{ data.title }}</h1>

<div mat-dialog-content>
  <p class="text-center mb-2 mt-1">Do you want to update to a different version?</p>
  <mat-label class="required-field">Version</mat-label>
  <mat-form-field class="w-100">
    <input type="text" matInput placeholder="Version" [formControl]="versionInputControl" />
    <mat-error>
      @if (versionInputControl.hasError('required')) {
        Version is <strong>required</strong>
      }
      @if (versionInputControl.hasError('pattern')) {
        The Version must be in the following format <strong>x.x.x</strong>
      } @else if (versionInputControl.hasError('minVersion')) {
        The version cannot be lower than <strong>current</strong>
      }
    </mat-error>
  </mat-form-field>
</div>
<div align="center" mat-dialog-actions>
  <button mat-stroked-button (click)="onCancelClick()">Cancel</button>

  <button
    class="button-success"
    mat-raised-button
    [disabled]="versionInputControl.value === data.defaultVersion"
    (click)="onConfirmClick()">
    <mat-icon [svgIcon]="'check'" />
    Update
  </button>
</div>
