/* src/app/shared/version-input-dialog/version-input-dialog.component.scss */
:host {
  position: relative;
}
.close-icon {
  z-index: 999;
  position: absolute;
  top: 5px;
  right: 8px;
  padding: 0;
  margin: 0;
  height: 20px;
  width: 18px;
}
.close-icon mat-icon {
  zoom: 75% !important;
}
/*# sourceMappingURL=version-input-dialog.component.css.map */
