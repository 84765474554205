import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class ValidationsService {
  minMaxValidator(form: AbstractControl): ValidationErrors | null {
    const min = form.get('min').value;
    const max = form.get('max').value;
    return min >= max ? { minMax: true } : null;
  }

  requiredWithStripHtmlValidator(control: AbstractControl): ValidationErrors | null {
    const trimmedValue = control.value.replace(/<[^>]*>|&nbsp;/g, '').trim();
    const isValid = trimmedValue !== '';
    return isValid ? null : { required: true };
  }

  uniqueLabelsValidator(parseFn = v => v.filter(x => !!x)): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const labels = parseFn(control.value);
      if (!labels.length) {
        return null;
      }
      return labels.length === new Set(labels).size ? null : { uniqueLabels: true };
    };
  }

  minVersionValidator(minVersion: string): ValidatorFn {
    return (control: AbstractControl): Record<string, any> | null => {
      const currentVersion = control.value;
      if (currentVersion && !this.isVersionGreaterOrEqual(currentVersion, minVersion)) {
        return { minVersion: true };
      }
      return null;
    };
  }

  private isVersionGreaterOrEqual(currentVersion: string, previousVersion: string): boolean {
    const currentParts: number[] = currentVersion.split('.').map(Number);
    const previousParts: number[] = previousVersion.split('.').map(Number);

    for (let i = 0; i < Math.max(currentParts.length, previousParts.length); i++) {
      const current = currentParts[i] || 0;
      const previous = previousParts[i] || 0;

      if (current > previous) {
        return true;
      }
      if (current < previous) {
        return false;
      }
    }
    return true;
  }
}
